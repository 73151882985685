<template>
  <div>
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <template v-else>
      <h4 v-if="projectId" class="pa-1 pl-2">{{$t('message.projects.project')}}: {{showProjectName}}</h4>
      <v-row class="mt-3 mb-2 mx-0" v-if="false">
        <v-col cols="12" class="pa-0 pl-1 pt-1">
          <v-autocomplete outlined dense :label="$t('message.equipments.equipment')" :items="listOfEquipments" hide-details v-model="activeEquipmentId" @change="getSingleRecord(activeEquipmentId)" item-text="equipmentName" item-value="id"></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="my-2 mx-0">
        <!-- <form-data :references.sync="formReferences" :model="modelObj" ref="equipmentFormRef"></form-data> -->
        <form-data :references.sync="formReferences" :model="modelObj" ref="equipmentFormRef">
          <template v-if="modelObj.id && listOfDocuments.length > 0" slot="equipment_documents">
            <documents :documents="listOfDocuments" :deleteIds="deleteDocumentIds"></documents>
          </template>
        </form-data>
      </v-row>
      <v-row class="mb-2 mx-0" v-if="modelObj.is_checklist_motor === 1 && !this.isReadonly">
        <v-speed-dial v-model="showItems" bottom fixed class="custom-speed-dial">
          <template v-slot:activator>
            <v-btn v-model="showItems" color="primary" dark fab>
              <v-icon v-if="showItems">mdi-close</v-icon>
              <v-icon v-else>mdi-menu</v-icon>
            </v-btn>
          </template>
          <v-btn class="text-capitalize" min-width="80px" color="primary" v-for="item in speedDialItems" :key="`${item.name}_menuitem`" :to="item.to">{{ item.name }}</v-btn>
        </v-speed-dial>
      </v-row>
    </template>
  </div>
</template>

<script>
import FormTemplate from '@/components/FormTemplate'
import { mapGetters } from 'vuex'
import { hostAppApi } from '../../plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'
import projectMixin from '@/mixins/projectMixin'
export default {
  mixins: [PermissionsMixin, projectMixin],
  data () {
    return {
      modelObj: {
        id: parseInt(this.$route.params.id),
        project_id: this.$route.query.project_id ? parseInt(this.$route.query.project_id) : 0,
        status: 1
      },
      listOfEquipments: [],
      listOfDocuments: [],
      deleteDocumentIds: [],
      activeEquipmentId: 0,
      showItems: false,
      hostRef: this.$cookie.get('AxentivPwa_host-ref'),
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      fieldsLoading: false,
      /* equipment crud */
      listOfProjects: [],
      listOfResponsibleUsers: [],
      listOfEquipmentLocations: [],
      loading: false,
      isReadonly: false,
      projectId: this.$route.query.project_id ? parseInt(this.$route.query.project_id) : 0,
      backUrl: '',
      hideDetails: true
    }
  },
  computed: {
    ...mapGetters(['formType']),
    formReferences () {
      return {
        title: !this.checkActionPermission(this.MODULE, this.CONSTANTS.EDIT) ? this.$t('message.equipments.viewEquipment') : this.modelObj.id ? this.$t('message.equipments.updateEquipment') : this.$t('message.equipments.addEquipment'),
        properties: [{
          model: 'project_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.modelObj.location_id ? [] : this.$_requiredValidation,
          items: this.listOfProjects,
          select_text: (item) => this.setProjectname(item),
          select_value: 'id',
          change: () => { this.modelObj.location_id = 0 },
          label: this.$t('message.projects.project'),
          class: 'xs12 sm6 md4 lg3 helptext-style',
          disabled: this.isReadonly,
          is_show: !this.$store.state.common.isProjectExcluded ? !this.projectId : false,
          helpText: this.$t('message.equipments.mustAllocateToProjectOrLocation'),
          helpTextShow: !this.modelObj.project_id && !this.modelObj.location_id
        }, {
          model: 'location_id',
          type: this.formType.AUTO_COMPLETE,
          rules: this.modelObj.project_id ? [] : this.$_requiredValidation,
          items: this.listOfEquipmentLocations,
          select_text: 'name',
          change: () => { this.modelObj.project_id = 0 },
          select_value: 'id',
          label: this.$t('message.deviation.location'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          is_show: !this.projectId
        }, {
          model: 'equipment_number',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.equipments.equipmentNumber'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 50
        }, {
          model: 'name',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.common.name'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 35
        }, {
          model: 'located',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.equipments.placement'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 35
        }, {
          model: 'description',
          type: this.formType.TEXT,
          rules: this.charLengthValidation(35),
          label: this.$t('message.deviation.description'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          hideDetails: this.hideDetails
        }, {
          model: 'dateof_purchase',
          type: this.formType.DATEPICKER,
          rules: [],
          picker: 'purchaseDatePicker',
          menu: 'purchaseDateMenu',
          color: 'primary lighten-1',
          label: this.$t('message.equipments.dateofPurchase'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly
        }, {
          model: 'warranty_date',
          type: this.formType.DATEPICKER,
          rules: [],
          picker: 'warrantyDatePicker',
          menu: 'warrantyDateMenu',
          color: 'primary lighten-1',
          label: this.$t('message.equipments.warrantydate'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly
        }, {
          model: 'responsible',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.deviation.responsible'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 50
        }, {
          model: 'vendor',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.equipments.vendor'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 35
        }, {
          model: 'product',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.equipments.product'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 35
        }, {
          model: 'model_number',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.equipments.modelNumber'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 15
        }, {
          model: 'serial_number',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.equipments.serialNumber'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 50
        }, {
          model: 'capacity',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.equipments.capacity'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 50
        }, {
          model: 'groups',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.equipments.groups'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly,
          max: 50
        }, {
          model: 'planned',
          type: this.formType.DATEPICKER,
          rules: [],
          picker: 'plannedDatePicker',
          menu: 'plannedDateMenu',
          color: 'primary lighten-1',
          label: this.$t('message.equipments.plannedCalibration'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly
        }, {
          model: 'scrapping',
          type: this.formType.DATEPICKER,
          rules: [],
          picker: 'scrapDatePicker',
          menu: 'scrapDateMenu',
          color: 'primary lighten-1',
          label: this.$t('message.equipments.scrapDate'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly
        }, {
          model: 'responsible_id',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listOfResponsibleUsers,
          select_text: 'name',
          select_value: 'id',
          label: this.$t('message.deviation.responsible'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly
        }, {
          model: 'status',
          type: this.formType.SELECT,
          rules: this.$_requiredValidation,
          items: this.listOfEquipmentStatus,
          select_text: 'text',
          select_value: 'value',
          label: this.$t('message.projects.status'),
          class: 'xs12 sm6 md4 lg3',
          disabled: this.isReadonly
        }, {
          model: 'notify_by_mail',
          rules: [],
          type: this.formType.CHECKBOX,
          class: 'xs12 sm6 md4 lg3 pt-2',
          label: this.$t('message.equipments.notifyByEmail'),
          disabled: this.isReadonly
        }, {
          model: 'files',
          type: this.formType.FILES,
          multiple: true,
          rules: this.$_maxFileSizeValidation(20, this.modelObj.files), // this.$_multiSelectValidation // size in MB
          accept: '.xlsx, .xls, image/*, .doc, .docx, .txt, .pdf',
          label: this.$t('message.deviation.upload_files'),
          class: 'xs12 sm4 md3',
          disabled: this.isReadonly,
          slot: 'equipment_documents',
          hideDetails: this.hideDetails
        }],
        buttons: [{
          name: 'action_handler',
          color: 'success',
          label: this.modelObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
          click: this.saveUpdateHandler,
          loading: this.loading,
          is_show: true,
          disabled: this.isReadonly
        }, {
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), // this.$router.push(this.backUrl),
          label: this.$t('message.login.back'),
          to: this.backUrl,
          is_show: true
        }]
      }
    },
    speedDialItems () {
      const speedDialItems = []
      // let to = `/equipments/${this.activeEquipmentId}/checklists`
      // if (this.projectId) to += `/${this.projectId}`
      const equipmentCheckList = {
        name: this.$t('message.checklist.title'),
        icon: 'mdi-book-plus',
        to: this.projectId ? `/equipments/${this.activeEquipmentId}/checklists?projectequipment=1` : `/equipments/${this.activeEquipmentId}/checklists`
      }
      speedDialItems.push(equipmentCheckList) // if (this.$store.state.common.includeEquipmentChecklist) speedDialItems.push(equipmentCheckList)
      return speedDialItems
    },
    listOfEquipmentStatus () {
      return [
        { text: this.$t('message.equipments.active'), value: 1 },
        { text: this.$t('message.equipments.quarantine'), value: 2 },
        { text: this.$t('message.equipments.discarded'), value: 3 }
      ]
    },
    showProjectName () {
      const project = this.listOfProjects.find(x => x.id === this.projectId)
      let name = ''
      if (project) name = this.setProjectname(project)
      return name
    }
  },
  created () {
    this.MODULE = 'equipment'
    if (this.$route.query.project_id) this.MODULE = 'projectequipment'
    this.isReadonly = !this.checkActionPermission(this.MODULE, this.CONSTANTS.EDIT)
    // this.getAllEquipments()
    this.getAllProjects()
    this.getAllUsers()
    this.getEquipmentLocations()
  },
  mounted () {
    if (this.$route.params.id) {
      // this.getAllEquipments()
      this.getSingleRecord(this.$route.params.id)
      this.activeEquipmentId = parseInt(this.$route.params.id)
    }
    // set back url
    this.backUrl = this.projectId ? `/projects/${this.projectId}/equipments` : '/equipments'
  },
  methods: {
    getSingleRecord (id) {
      this.fieldsLoading = true
      hostAppApi.get(`https://${this.hostRef}/api/equipment/${id}/edit?user_id=${this.hostUserId}`)
        .then((response) => {
          const transformData = { date: { list: ['warranty_date', 'scrapping'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' } }
          const model = this.$formatter.formatModel(response.data, transformData)
          this.listOfDocuments = this.$formatter.removeInvalidFromArrayObject(model.documents)
          if (response.data) this.modelObj = this.$formatter.cloneVariable(model)
        })
        .finally(() => {
          this.fieldsLoading = false
        })
    },
    getAllEquipments () {
      this.getSetListItems(`https://${this.hostRef}/api/equipment?only_items=1`, 'listOfEquipments') // 4 is all, 2 is myall
    },
    getSetListItems (url, listName) {
      hostAppApi.get(url).then((response) => {
        if (response.data) {
          if (listName === 'listOfEquipments') {
            const list = this.$formatter.cloneVariable(response.data.data)
            list.forEach(element => {
              // eslint-disable-next-line camelcase
              element.equipmentName = element.equipment_number ? `${element.equipment_number} - ` : ''
              element.equipmentName += element.name || ''
            })
            this[listName] = list
          }
        }
      })
    },
    /* equipment crud */
    getAllProjects () {
      this.$api.execute('get', `projects/get_as_simplified_for_pwa?${this.isAdmin() ? 3 : 2}`).then((response) => {
        this.listOfProjects = response.data
      })
    },
    getAllUsers () {
      this.$api.execute('get', 'users').then(response => {
        const users = response.data
        for (let i = 0; i < users.length; i++) {
          users[i].name = `${users[i].firstname || ''} ${users[i].lastname || ''}`
        }
        const responsibleUsers = users.filter(x => x.active) //  && x.is_responsible - deviation
        this.listOfResponsibleUsers = responsibleUsers
      })
    },
    saveUpdateHandler () {
      if (this.$refs.equipmentFormRef.$refs.validateForm.validate()) {
        this.loading = true
        let model = this.modelObj
        const userid = this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0
        if (userid) model.user_id = parseInt(userid)
        // let url = `https://${this.hostRef}/api/`
        // const method = model.id ? 'put' : 'post'
        // url += model.id ? `equipment/${model.id}` : 'equipment'
        const text = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
        model = this.$formatter.removeInvalidFromObject(model)
        // hostAppApi[method](url, model, { headers: { language: this.$i18n.locale } })
        const formData = new FormData()
        const files = this.modelObj.files
        if (files) {
          files.forEach((file, i) => {
            formData.append('document[' + i + ']', file)
          })
        }
        const newobj = this.$formatter.removeInvalidFromObject(model)
        for (const key in newobj) {
          // if (key !== 'files') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || null)
          // if (key !== 'risk_analysis' && key !== 'include_in_report' && key !== 'files' && key !== 'timeless' && key !== 'inspection' && key !== 'loan' && key !== 'deviation_registered' && key !== 'checklist') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || null)
          // if (key !== 'risk_analysis' && key !== 'include_in_report' && key === 'timeless' && key === 'inspection' && key === 'loan' && key === 'deviation_registered' && key === 'checklist') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || 0)
          // if (key === 'risk_analysis' && key === 'include_in_report') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || 0)
          if (key !== 'files') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || newobj[key])
        }
        const header = {
          'Content-Type': 'multipart/form-data',
          language: this.$i18n.locale
        }
        let url = `https://${this.hostRef}/api/`
        url += model.id ? `equipment/${model.id}/update` : 'equipment'
        const ids = [...this.deleteDocumentIds]
        if (model.id && ids.length > 0) formData.append('document_delete_ids', ids)
        hostAppApi.post(url, formData, { headers: header })
          .then(response => {
            this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
            // const url = this.projectId ? `/projects/${this.projectId}/equipments` : '/equipments'
            this.$router.go(-1)
            // this.$router.push(this.backUrl)
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.message) {
              const errorList = error.response.data.message
              this.$root.$emit('snackbar', { snackbar: true, color: 'error', isException: true, text: errorList })
            } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: error })
          })
          .finally(() => {
            this.loading = false
          })
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    getEquipmentLocations () {
      hostAppApi.get(`https://${this.hostRef}/api/get_active_equipment_locations`)
        .then((response) => {
          this.listOfEquipmentLocations = response.data
        })
    },
    charLengthValidation (length) {
      return [
        (v) => {
          const data = v ? (v && v.length <= length) || this.$t('message.common.charsLimit35', { length: length }) : true
          if (typeof data !== 'boolean') this.hideDetails = false
          else this.hideDetails = true
          return data
        }
      ]
    }
  },
  components: {
    'form-data': FormTemplate,
    documents: () => import('@/components/Documents/Documents')
  }
}
</script>

<style>
.custom-speed-dial div.v-speed-dial__list {
  align-items: baseline !important;
}
@media only screen and (min-width: 768px) {
  .v-speed-dial--bottom {
    bottom: 14vh !important;
  }
}
@media only screen and (max-width: 767px) {
  .v-speed-dial--bottom {
    bottom: 10vh !important;
  }
}
.helptext-style > span {
  font-size: 80%;
  padding-left: 2%;
  color: red;
}
</style>
